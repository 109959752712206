<template>
  <div>
    <b-row class="kb-search-content-info match-height">
      <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imagePayers"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Client-payers-list'})"
        >
          <h2>Mis clientes</h2>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imageReceipts"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Client-register-receipt'})"
        >
          <h2>Mis Operaciones en trámite</h2>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imagePortfolio"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Client-current-receipts-portfolio'})"
        >
          <h2>Mis documentos negociados</h2>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imageFunds"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Client-outstanding-guarantee-funds'})"
        >
          <h2>Mis Fondos de garantía</h2>
        </b-card>
      </b-col>
      <!-- <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imageServices"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Client-services-list'})"
        >
          <h2>Otros Servicios</h2>
        </b-card>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCard,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
  },
  data() {
    return {
      image: require('@/assets/images/illustration/demand.svg'),
      imagePayers: require('@/assets/images/efide/clientes.svg'),
      imageReceipts: require('@/assets/images/efide/operaciones-en-tramite.svg'),
      imagePortfolio: require('@/assets/images/efide/documentos-negociados.svg'),
      imageFunds: require('@/assets/images/efide/garantias.svg'),
      imageServices: require('@/assets/images/efide/Otros servicios-panel.svg'),
    }
  },
}
</script>

<style>

</style>
